<template>
  <div>
    <v-tabs background-color="background lighten-1">
      <v-tabs-slider></v-tabs-slider>

      <v-tab to="/admin/businesses">Businesses</v-tab>

      <v-tab v-if="isSuperUser" to="/admin/apps" >Applications</v-tab>

      <!-- <v-tab v-if="isSuperUser" to="/admin/api-tokens" >API Tokens</v-tab> -->
    </v-tabs>
    <!-- <v-col cols="2" class="py-0 pr-0">
      <v-navigation-drawer class="mx-4" min-height="90vh" permanent color="background">
        <v-list class="py-0">
          <v-list-item to="/admin/businesses" active-class="primary--text">
            <v-list-item-icon class="mx-0 mr-4">
              <v-icon>business</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Businesses</v-list-item-title>
          </v-list-item>
          <v-divider class="mx-3"></v-divider>

          <v-list-item v-if="isSuperUser" to="/admin/apps" active-class="primary--text">
            <v-list-item-icon class="mx-0 mr-4">
              <v-icon>apps</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Apps</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider class="mx-3"></v-divider>
      </v-navigation-drawer>
    </v-col>-->
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      vg_user: 'auth/user'
    }),
    isSuperUser() {
      return this.vg_user.type === 'super'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
